<template>
  <div class="family">
    <div
      v-for="(item, index) in peolist"
      :key="index"
      class="item"
      @click="detail(item.patient_id)"
    >
      <div class="i-l" v-html="family_ties(item.family_ties)"></div>
      <div class="i-r">
        <div class="info">
          <div class="name">{{ item.patientname }}</div>
          <div class="id">就诊卡号：{{ item.patient_id }}</div>
        </div>
        <img class="icon" src="../assets/images/xiangyou.png" />
      </div>
    </div>
    <div class="empty" v-if="empty">
      <img
        src="https://hrp.weilaihos.com/uploads/20210121/98afe4a6068ee7b9cf59bbf25fb16377.png"
        alt=""
      />
      <text class="empty-text">暂无更多数据</text>
    </div>
  </div>
</template>

<script>
import { getFamily } from '../services/home'
export default {
  data() {
    return {
      list: [],
      peolist: [],
      empty: false
    }
  },
  async created() {
    const res = await getFamily(this.$route.query.user_id)
    if (res.data.code === 200) {
      this.list = res.data.data.rows
    } else {
      return res.data.msg
    }
    this.list.forEach(item => {
      if (item.mobile_num !== this.$route.query.user_name) {
        this.peolist.push(item)
      }
    })
    if (this.peolist.length === 0) {
      this.empty = true
    }
  },
  methods: {
    family_ties(name) {
      if (!name) return ''
      const list = name.split('')
      return list.reduce((r, c) => {
        return (r += `<div>${c}</div>`)
      }, '')
    },
    back() {
      this.$router.go(-1)
    },
    detail(id) {
      localStorage.setItem('patientId', id)
      this.$router.push({
        path: '/people-report?user_id=' + this.$route.query.user_id,
        query: { patientId: id }
      })
    }
  }
}
</script>

<style lang="scss">
#app {
  min-height: initial;
}
.empty {
  height: 100vh;
  width: 100%;
  text-align: center;
  background: #ffffff;
  color: #909399;
}
.empty img {
  display: block;
  width: 375px;
  height: 293px;
}
.empty .empty-text {
  width: 100%;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  position: absolute;
  top: 230px;
  left: 0px;
  text-align: center;
}
.family {
  min-height: calc(100vh - 24px);
  width: 100%;
  background: #f9f9f9;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 14px;
  color: #999999;
  padding: 10px 15px;
}

.item {
  height: 72px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 2px 0px #eeeeee;
  border-radius: 10px 4px 4px 10px;
  display: flex;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.i-l {
  height: 100%;
  width: 24px;
  background: #4397ff;
  box-shadow: 0px 1px 2px 0px #eeeeee;
  border-radius: 10px 0px 0px 10px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.i-r {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.3% 0 3.2%;

  .info {
    .name {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
    }

    .id {
      height: 20px;
      line-height: 20px;
    }
  }
}

.icon {
  height: 16px;
  width: 16px;
}
</style>
